<template>
  <div
    id="contents"
    :class="tabIndex == 0 ? 'ledger_raw_materials' : 'ledger_purch'"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToProductLedgerPage'"
          :options="[
            { title: '제품 원장', name: 'product_ledger' },
            { title: '매출처 원장', name: 'sales_ledger' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="{ active: tabIndex == 0 }">
            <a @click="SetOpenTabIndex(0)">제품 원장</a>
          </li>
          <li :class="{ active: tabIndex == 1 }">
            <a @click="SetOpenTabIndex(1)">매출처 원장</a>
          </li>
        </ul>
      </div>
      <sales-ledger-form v-if="tabIndex == 1"></sales-ledger-form>
      <component :is="dynamicComponent" v-if="tabIndex == 0"></component>
      <!-- <component :is="dynamicComponent2" v-if="tabIndex == 1"></component> -->
    </div>
  </div>
</template>

<script>
import ClosePageMixin from '@/mixins/closePage';
// import ProductLedgerForm from '@/views/forms/Product/Ledger/ProductLedgerForm';
import SalesLedgerForm from '@/views/forms/Product/Ledger/SalesLedgerForm';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import FavoriteMixin from '@/mixins/favorite';
import { mapGetters, mapMutations } from 'vuex';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    // ProductLedgerForm,
    SalesLedgerForm,
    AsideSelectric,
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromProductLedgerPage',
    }),
    dynamicComponent() {
      if (Number(localStorage.getItem('saupKey')) == 17)
        return () =>
          import('@/views/forms/Product/Ledger/17/CNSTechProductLedgerForm');
      else
        return () => import('@/views/forms/Product/Ledger/ProductLedgerForm');
    },
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToProductLedgerPage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitProductLedgerPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss" scoped></style>
