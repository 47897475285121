<template>
  <div class="article">
    <div class="tbl_wrap">
      <form>
        <div class="tbl_option">
          <div class="search_opt">
            <div class="input_text">
              <label>조회 기간</label>
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="start_date"
                @change="setStartDate($event)"
                v-if="showSearchDate"
              />
            </div>
            <p>~</p>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="end_date"
                @change="setEndDate($event)"
                v-if="showSearchDate"
              />
            </div>
            <button
              type="button"
              class="btn_sub1 refresh_btn"
              @click="refreshData"
            >
              재조회
            </button>
            <button
              class="btn_sub2 btn_print"
              v-if="include_company_list.length > 0"
              @click.prevent="openModal"
            >
              <i class="ico_print"></i>출력
            </button>
            <vendor-print-modal
              v-if="isLedgerModalOpen"
              :include_company_list="include_company_list"
              :startDate="start_date"
              :endDate="end_date"
              :checkPage="checkPage"
              @onclose="closeModal"
            ></vendor-print-modal>
            <div
              class="modal_layer"
              v-if="isLedgerModalOpen"
              @click="closeModal"
            ></div>
            <button
              v-if="include_company_list.length > 0"
              class="excel_btn"
              @click.prevent="exportExcel"
            >
              엑셀로 저장
            </button>
          </div>
          <div class="ledger_opt">
            <button
              type="button"
              class="btn_ledger"
              @click="showCompanySearch = true"
            >
              매출처 선택
            </button>

            <transition-group
              name="selected"
              mode="in-out"
              tag="ul"
              type="transition"
              class="selected_items_wrap"
              v-if="include_company_list.length > 0"
            >
              <li
                class="selected_item"
                v-for="(item, index) in include_company_list"
                :key="item"
              >
                <span>{{ findInfoFromId(companys, item).name }}</span>
                <button
                  type="button"
                  class="delete_item"
                  @click="include_company_list.splice(index, 1)"
                ></button>
              </li>
            </transition-group>
          </div>
        </div>
      </form>
      <div
        class="ledger_info purch_info"
        v-if="
          include_company_list != undefined && include_company_list.length == 1
        "
      >
        <div class="led_info">
          <h5>매출처 정보</h5>
          <table>
            <tr>
              <th>매출처명</th>
              <td>
                {{ findInfoFromId(companys, include_company_list[0]).name }}
              </td>
              <th>대표자명</th>
              <td>
                {{
                  findInfoFromId(companys, include_company_list[0])
                    .representative
                }}
              </td>
              <th>사업자 번호</th>
              <td>
                {{ findInfoFromId(companys, include_company_list[0]).saup_no }}
              </td>
            </tr>
            <tr>
              <th>취급 제품</th>
              <td>
                {{ getPrimaryProduct() }}
              </td>
              <th>거래개시일</th>
              <td>
                {{
                  findInfoFromId(companys, include_company_list[0])
                    .business_date
                }}
              </td>
              <th>현잔고</th>
              <td>&#8361; {{ company_balance | makeComma }}</td>
            </tr>
            <tr>
              <th>주소</th>
              <td colspan="5">
                {{
                  findInfoFromId(companys, include_company_list[0]).address !=
                  null
                    ? `${
                        findInfoFromId(companys, include_company_list[0])
                          .address
                      }`
                    : ''
                }}
                {{
                  findInfoFromId(companys, include_company_list[0])
                    .detail_address != null
                    ? `${
                        findInfoFromId(companys, include_company_list[0])
                          .detail_address
                      }`
                    : ''
                }}
              </td>
            </tr>
          </table>
        </div>
        <div class="led_main">
          <h5>판매 제품</h5>
          <table>
            <tr>
              <th>1위</th>
              <td>
                {{
                  group_product.length > 0
                    ? findInfoFromId(products, group_product[0].product_id).name
                    : ''
                }}
              </td>
            </tr>
            <tr>
              <th>2위</th>
              <td>
                {{
                  group_product.length > 1
                    ? findInfoFromId(products, group_product[1].product_id).name
                    : ''
                }}
              </td>
            </tr>
            <tr>
              <th>3위</th>
              <td>
                {{
                  group_product.length > 2
                    ? findInfoFromId(products, group_product[2].product_id).name
                    : ''
                }}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <h6>
        조회수 :
        {{
          display_arr.filter(x => x.sum_date == null || x.sum_date == undefined)
            .length
        }}건
      </h6>
      <div class="mes_tbl_wrap sales_print_table">
        <table class="mes_tbl" id="excel_table">
          <colgroup class="sales_ledger">
            <col
              v-for="(n, index) in include_company_list.length == 1 ? 14 : 13"
              :key="index"
            />
          </colgroup>
          <thead>
            <tr>
              <th :colspan="include_company_list.length == 1 ? 14 : 13">
                <h2>매출처 원장</h2>
              </th>
            </tr>
            <tr>
              <th>조회기간</th>
              <td :colspan="include_company_list.length == 1 ? 13 : 12">
                <span>{{ `${start_date}~${end_date}` }}</span>
              </td>
              <!-- <th
                v-for="(n, index) in include_company_list.length == 1 ? 12 : 11"
                :key="index"
              ></th> -->
            </tr>
            <tr>
              <th>매출처</th>
              <td
                v-for="(n, index) in include_company_list.length == 1 ? 13 : 12"
                :key="index"
              >
                {{ addComapny(index) }}
              </td>
            </tr>
            <tr></tr>
            <tr class="table_title">
              <th>합계</th>
              <th>일자</th>
              <th>구분</th>
              <th>매출처명</th>
              <th>제품명</th>
              <th>수량(단위)</th>
              <th>단가</th>
              <th>공급가</th>
              <th>부가세</th>
              <th>합계액</th>
              <th>할인액</th>
              <th>매출액</th>
              <th>수금액</th>
              <th v-if="include_company_list.length == 1">잔고</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in display_arr"
              :key="index"
              :class="{
                null: item.sum_date == undefined,
                default: item.sum_date == '전 잔고',
                day: item.sum_date == '일 합계',
                month: item.sum_date == '월 합계',
              }"
            >
              <td>
                <center>
                  {{ item.sum_date == undefined ? '' : item.sum_date }}
                </center>
              </td>
              <td>
                <center>
                  {{
                    item.sum_date == undefined
                      ? item.input_date
                      : item.sum_date == '월 합계'
                      ? item.input_date.substr(0, 7)
                      : item.input_date
                  }}
                </center>
              </td>
              <td>
                <center>
                  {{
                    item.sum_date == undefined
                      ? item.out_cost == undefined
                        ? findInfoFromId(salesTypes, item.sales_type_id).name
                        : findInfoFromId(
                            collectionTypes,
                            item.collection_type_id,
                          ).name
                      : ''
                  }}
                </center>
              </td>
              <td class="ellipsis">
                {{
                  item.sum_date == undefined
                    ? findInfoFromId(companys, item.company_id).name
                    : ''
                }}
              </td>
              <td class="ellipsis">
                {{
                  item.sum_date == undefined
                    ? findInfoFromId(products, item.product_id).name
                    : ''
                }}
              </td>
              <td class="text_right">
                <center>
                  <b>
                    {{
                      item.sum_date == undefined
                        ? item.out_cost == undefined
                          ? `${$makeComma(item.quantity)}`
                          : ''
                        : ''
                    }}
                  </b>
                  {{
                    item.sum_date == undefined
                      ? item.out_cost == undefined
                        ? item.box_unit_id == null
                          ? `(${findInfoFromId(units, item.unit_id).name})`
                          : `(${findInfoFromId(boxs, item.box_unit_id).name})`
                        : ''
                      : ''
                  }}
                </center>
              </td>
              <td class="text_right">
                {{
                  item.sum_date == undefined
                    ? item.out_cost == undefined
                      ? `${$makeComma(item.cost)}`
                      : ''
                    : ''
                }}
              </td>
              <td class="text_right">
                {{
                  item.sum_date == undefined
                    ? item.out_cost == undefined
                      ? `${$makeComma(item.supply_value)}`
                      : ''
                    : ''
                }}
              </td>
              <td class="text_right">
                {{
                  item.sum_date == undefined
                    ? item.out_cost == undefined
                      ? `${$makeComma(item.tax)}`
                      : ''
                    : ''
                }}
              </td>
              <td class="text_right">
                {{
                  item.total_cost == undefined
                    ? ''
                    : `${$makeComma(item.total_cost)}`
                }}
              </td>
              <td class="text_right">
                {{
                  item.discount == undefined
                    ? ''
                    : `${$makeComma(item.discount)}`
                }}
              </td>
              <td class="text_right">
                {{
                  item.total_cost == undefined
                    ? ''
                    : `${$makeComma(item.total_cost - item.discount)}`
                }}
              </td>
              <td class="text_right">
                {{
                  item.out_cost == undefined
                    ? ''
                    : `${$makeComma(item.out_cost)}`
                }}
              </td>
              <td v-if="include_company_list.length == 1" class="text_right">
                {{
                  item.curr_balance == undefined
                    ? ''
                    : `${$makeComma(item.curr_balance)}`
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <company-search
      v-if="showCompanySearch"
      :search_type="1"
      @onclose="showCompanySearch = false"
      @onselect="
        $event => {
          pushIncludeCompany($event);
          showCompanySearch = false;
        }
      "
    ></company-search>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import { yyyymmdd } from '@/utils/func';
import CompanySearch from '@/layouts/components/search-popup/CompanySearch';
import VendorPrintModal from '@/layouts/components/VendorPrintModal';

export default {
  mixins: [FETCH_MIXIN],
  components: {
    CompanySearch,
    VendorPrintModal,
  },
  data() {
    return {
      showSearchDate: false,
      showCompanySearch: false,
      isLedgerModalOpen: false,
      checkPage: '매출처',
    };
  },
  computed: {
    ...mapGetters({
      sales_company_balance: 'getSalesCompanyBalanceList',
      sales_account: 'getSalesAccountFromSalesLedger',
      start_date: 'getStartDateFromSalesLedger',
      end_date: 'getEndDateFromSalesLedger',
      include_company_list: 'getIncludeCompanyListFromSalesLedger',
      companys: 'getCompany',
      products: 'getProduct',
      product_in_out_types: 'getProductInOutType',
      salesTypes: 'getSalesTypeNotSort',
      units: 'getUnitCodes',
      collections: 'getCollection',
      collectionTypes: 'getCollectionType',
      boxs: 'getBoxs',
      seller_companys: 'getSellerCompany',
    }),

    mapAccount() {
      try {
        if (this.sales_account != undefined) {
          const sales_account = this.lodash.clonedeep(this.sales_account);
          sales_account.forEach(element => {
            element.sales_product_list.forEach(el2 => {
              el2.company_id = element.sales.company_id;
              el2.vat_id = element.sales.vat_id;
              el2.sales_type_id = element.sales.sales_type_id;
            });
          });

          let map = this.lodash.clonedeep(sales_account).map(x => {
            const create_time_temp = this.lodash.clonedeep(x.create_time);
            x = x.sales_product_list;
            x.map(y => {
              y.create_time = create_time_temp;
              return y;
            });
            return x;
          });

          if (map.length > 0) {
            map = map.reduce((a, b) => a.concat(b));

            for (let index = 0; index < map.length; index++) {
              const mapChk = map[index].product_in_out_list.find(
                y =>
                  y.product_in_out_type_id ==
                    this.product_in_out_types.find(
                      z => z.detail == 'process in',
                    ).id ||
                  y.product_in_out_type_id ==
                    this.product_in_out_types.find(z => z.detail == 'sales out')
                      .id ||
                  y.product_in_out_type_id ==
                    this.product_in_out_types.find(z => z.detail == 'using out')
                      .id ||
                  y.product_in_out_type_id ==
                    this.product_in_out_types.find(z => z.detail == 'return in')
                      .id,
              );

              if (mapChk != undefined) {
                map[index].create_time = map[index].product_in_out_list.find(
                  y =>
                    y.product_in_out_type_id ==
                      this.product_in_out_types.find(
                        z => z.detail == 'process in',
                      ).id ||
                    y.product_in_out_type_id ==
                      this.product_in_out_types.find(
                        z => z.detail == 'sales out',
                      ).id ||
                    y.product_in_out_type_id ==
                      this.product_in_out_types.find(
                        z => z.detail == 'using out',
                      ).id ||
                    y.product_in_out_type_id ==
                      this.product_in_out_types.find(
                        z => z.detail == 'return in',
                      ).id,
                ).create_time;
              }

              map[index].input_date = map[index].create_time.substr(0, 10);
              if (
                map[index].sales_type_id ==
                this.salesTypes.find(z => z.detail == 'return sales').id
              ) {
                map[index].supply_value *= -1;
                map[index].tax *= -1;
                map[index].total_cost *= -1;
                map[index].discount *= -1;
              }
            }
          }
          const collections = this.lodash.clonedeep(this.collections);
          collections.forEach(element => {
            element.out_cost = element.total_value;
            element.create_time = element.input_date + ' 23:59:99.999999';
            map.push(element);
          });
          return map;
        } else {
          return [];
        }
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    group_day() {
      let clone = this.lodash.clonedeep(
        this.mapAccount.filter(
          x =>
            this.include_company_list.includes(x.company_id) &&
            (x.total_cost != 0 || x.out_cost != 0),
        ),
      );
      if (clone == undefined) {
        return;
      }

      const obj = clone
        .sort(
          (a, b) =>
            Number(
              a.create_time
                .replace(/-/gi, '')
                .replace(/:/gi, '')
                .replace(' ', ''),
            ) -
            Number(
              b.create_time
                .replace(/-/gi, '')
                .replace(/:/gi, '')
                .replace(' ', ''),
            ),
        )
        .reduce(function(rv, x) {
          (rv[x['input_date']] = rv[x['input_date']] || []).push(x);
          return rv;
        }, {});

      let arr = [];
      let count = 100000;
      console.log(obj);
      const reducer = (accumulator, currentValue) =>
        accumulator + Number(currentValue.toFixed(6));
      for (let k in obj) {
        arr.push({
          date: k,
          month: k.substr(0, 7),
          element: obj[k],
        });

        const objInTemp = obj[k]
          .filter(x => x.collection_type_id == undefined)
          .map(x => x.total_cost);

        const objDiscountTemp = obj[k]
          .filter(x => x.collection_type_id == undefined)
          .map(x => x.discount);

        const objOutTemp = obj[k]
          .filter(x => x.collection_type_id != undefined)
          .map(x => x.total_value);

        const sumInCost = Number(objInTemp.reduce(reducer, 0).toFixed(6));
        const sumDiscountCost = Number(
          objDiscountTemp.reduce(reducer, 0).toFixed(6),
        );
        const sumOutCost = Number(objOutTemp.reduce(reducer, 0).toFixed(6));
        obj[k].push({
          sum_date: '일 합계',
          input_date: k,
          id: count++,
          total_cost: sumInCost,
          discount: sumDiscountCost,
          out_cost: sumOutCost,
        });
      }

      return arr;
    },
    group_month() {
      const obj = this.group_day.reduce(function(rv, x) {
        (rv[x['month']] = rv[x['month']] || []).push(x);
        return rv;
      }, {});
      let arr = [];
      let count = 9999999;
      for (let k in obj) {
        arr.push({
          date: k,
          element: obj[k],
        });
        const day_list = obj[k]
          .map(x => x.element)
          .reduce((a, b) => a.concat(b))
          .filter(x => x.sum_date !== undefined);
        const sumAllInCost = day_list
          .map(x => x.total_cost)
          .reduce((a, b) => this.$decimalAdd(a, b));
        const sumAllDiscountCost = day_list
          .map(x => x.discount)
          .reduce((a, b) => this.$decimalAdd(a, b));
        const sumAllOutCost = day_list
          .map(x => x.out_cost)
          .reduce((a, b) => this.$decimalAdd(a, b));

        obj[k].push({
          element: {
            sum_date: '월 합계',
            input_date: k + '-00',
            id: count++,
            total_cost: sumAllInCost,
            discount: sumAllDiscountCost,
            out_cost: sumAllOutCost,
          },
        });
      }
      return arr;
    },
    display_arr() {
      try {
        const temp = this.group_month.map(x => x.element);
        let list_ = [];

        if (temp !== undefined) {
          temp.forEach(e => {
            e.forEach(x => {
              list_ = list_.concat(x.element);
            });
          });
        }
        // return list_;

        const reverse = list_.reverse();
        if (this.include_company_list.length == 1) {
          const company_balance = this.lodash.clonedeep(this.company_balance);
          let curr_balance = company_balance;

          reverse.forEach(x => {
            if (x.sum_date == undefined) {
              if (x.product_in_out_list != undefined) {
                x.curr_balance = curr_balance;
                curr_balance -= x.total_cost - x.discount;
              } else {
                x.curr_balance = curr_balance;
                curr_balance += x.out_cost;
              }
            } else {
              x.curr_balance = curr_balance;
            }
          });
          reverse.push({
            input_date: this.lodash.clonedeep(this.start_date),
            sum_date: '전 잔고',
            curr_balance: curr_balance,
          });
        }

        return reverse
          .reverse()
          .filter(
            x =>
              Number(x.input_date.replace(/-/gi, '')) <=
                Number(this.end_date.replace(/-/gi, '')) &&
              (this.$makeNumber(x.out_cost) != 0 ||
                this.$makeNumber(x.total_cost) != 0 ||
                Object.keys(x).includes('sum_date')),
          );
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    company_balance() {
      if (this.include_company_list.length == 1) {
        if (
          this.companys.find(x => x.id == this.include_company_list[0]) !=
          undefined
        ) {
          return this.companys.find(x => x.id == this.include_company_list[0])
            .curr_balance;
        } else return 0;
      } else {
        return 0;
      }
    },
    group_product() {
      let clone = this.lodash.clonedeep(this.display_arr);
      const obj = clone.reduce(function(rv, x) {
        (rv[x['product_id']] = rv[x['product_id']] || []).push(x);
        return rv;
      }, {});
      console.log(obj);
      let list_ = [];
      for (let k in obj) {
        if (k != 'undefined') {
          list_.push({
            product_id: k,
            count: obj[k].length,
          });
        }
      }
      return list_.sort((a, b) => b.count - a.count);
    },
  },
  methods: {
    openModal() {
      if (
        this.include_company_list !== undefined ||
        this.include_company_list.length > 0
      ) {
        this.isLedgerModalOpen = true;
      }
    },
    closeModal() {
      let ledgerTableClone = document.querySelector(
        '.ledger_print_modal .modal_body',
      );
      let ledgerTable = ledgerTableClone.querySelector('.sales_print_table');
      ledgerTable.parentNode.removeChild(ledgerTable);

      this.isLedgerModalOpen = false;
    },
    getPrimaryProduct() {
      try {
        const matcom = this.lodash.clonedeep(
          this.seller_companys.filter(
            x => x.company_id == this.include_company_list[0],
          ),
        );
        if (matcom == undefined) {
          return [];
        }
        if (matcom.length >= 1) {
          const repre = this.findInfoFromId(this.products, matcom[0].product_id)
            .name;
          if (matcom.length >= 2) {
            return `${repre} 외 ${matcom.length - 1}개 제품`;
          } else {
            return repre;
          }
        } else {
          return '';
        }
      } catch (error) {
        console.log(error);
        return '';
      }
    },
    async setStartDate(e) {
      if (e.target.value != '') {
        await this.$store.commit('setStartDateToSalesLedger', e.target.value);
        await this.FETCH_WITH_PAYLOAD(
          'FETCH_SALES_ACCOUNT_DAY_ALL_TO_SALES_LEDGER',
          {
            start: this.start_date,
            end: '2500-01-01',
          },
          '구매 내역',
        );
        await this.FETCH_WITH_PAYLOAD(
          'FETCH_COLLECTION',
          {
            start: this.start_date,
            end: '2500-01-01',
          },
          '수금내역',
        );
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        await this.$store.commit(
          'setStartDateToSalesLedger',
          yyyymmdd(new Date()),
        );
      }
    },
    setEndDate(e) {
      if (e.target.value != '') {
        this.$store.commit('setEndDateToSalesLedger', e.target.value);
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToSalesLedger', yyyymmdd(new Date()));
      }
    },
    pushIncludeCompany(e) {
      if (!this.include_company_list.includes(e.id)) {
        this.include_company_list.push(e.id);
      }
    },
    async refreshData() {
      await this.FETCH_WITH_PAYLOAD_NO_SPINNER('FETCH_COMPANY');
      await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
        'FETCH_SALES_ACCOUNT_DAY_ALL_TO_SALES_LEDGER',
        {
          start: this.start_date,
          end: '2500-01-01',
        },
        '구매 내역',
      );
      await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
        'FETCH_COLLECTION',
        {
          start: this.start_date,
          end: '2500-01-01',
        },
        '수금내역',
      );
    },
    FETCH_BOX() {
      this.showSpinner();
      this.$store
        .dispatch('FETCH_BOX')
        .then(response => {
          console.log(response);
        })
        .catch(() => {
          this.openOneButtonModal(
            '로드 중 오류',
            '박스 정보를 불러오는 중 오류 발생.',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    addComapny(index) {
      if (
        this.include_company_list != undefined &&
        this.include_company_list.length > 0
      ) {
        return this.findInfoFromId(
          this.companys,
          this.include_company_list[index],
        ).name;
      } else {
        return '';
      }
    },
    exportExcel() {
      let table = document.querySelector('#excel_table');
      let styles = `<style>
          #excel_table{width: 100%;border: 1px solid #ddd;}
          tr:first-child,tr:nth-child(2),tr:nth-child(3) {display:block;} 
          tr.table_title th {background-color: #f6fafd;border-top: 1px solid #ddd;border-left: 1px solid #ddd;}
          tbody td {border-top: 1px solid #ddd;border-left: 1px solid #ddd;}
          tbody td:last-child {border-right: 1px solid #ddd;}
          tbody tr:last-child td {border-bottom: 1px solid #ddd;}
          tbody tr.default td {background-color: #f0f7fc;}
          tbody tr.day td {background-color: #f0ffee;}
          tbody tr.month td {background-color: #fffbd6;}
        </style>`;

      let uri = 'data:application/vnd.ms-excel;base64,';
      let template =
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->' +
        styles +
        '</head><body>{table}</body></html>';
      let base64 = function(s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      };
      let format = function(s, c) {
        return s.replace(/{(\w+)}/g, function(m, p) {
          return c[p];
        });
      };
      let ctx = {
        table: table.outerHTML.split('₩').join(''),
      };
      let a = document.createElement('a');
      a.href = uri + base64(format(template, ctx));
      a.download = `${
        this.include_company_list.length == 1
          ? this.findInfoFromId(this.companys, this.include_company_list[0])
              .name
          : `${
              this.findInfoFromId(this.companys, this.include_company_list[0])
                .name
            }외${this.include_company_list.length - 1}개`
      }_${this.start_date}~${this.end_date}.xls`;
      a.click();
    },
  },

  async created() {
    if (this.start_date == null) {
      let date = new Date();
      await this.$store.commit('setEndDateToSalesLedger', yyyymmdd(date));
      let date2 = new Date(date.setDate(date.getDate() - 7));
      await this.$store.commit('setStartDateToSalesLedger', yyyymmdd(date2));
    }
    if (this.sales_company_balance.length < 1) {
      await this.FETCH('FETCH_SALES_COMPANY_BALANCE');
    }
    await this.FETCH('FETCH_COMPANY');
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY');
    }
    if (this.product_in_out_types.length < 1) {
      await this.FETCH('FETCH_PRODUCT_IN_OUT_TYPE');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT');
    }
    if (this.boxs.length < 1) {
      await this.FETCH_BOX();
    }
    if (this.salesTypes.length < 1) {
      await this.FETCH('FETCH_SALES_TYPE');
    }
    if (this.seller_companys.length < 1) {
      await this.FETCH('FETCH_SELLER_COMPANY');
    }
    if (this.collectionTypes.length < 1) {
      await this.FETCH('FETCH_COLLECTION_TYPE');
    }

    await this.FETCH_WITH_PAYLOAD(
      'FETCH_COLLECTION',
      {
        start: this.start_date,
        end: '2500-01-01',
      },
      '수금내역',
    );

    if (this.sales_account.length < 1) {
      await this.FETCH_WITH_PAYLOAD(
        'FETCH_SALES_ACCOUNT_DAY_ALL_TO_SALES_LEDGER',
        {
          start: this.start_date,
          end: '2500-01-01',
        },
        '구매 내역',
      );
    }

    this.showSearchDate = true;
  },
};
</script>

<style lang="scss" scoped>
.selected_item {
  transition: all 0.5s;
}
.selected-enter,
.selected-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.selected-leave-active {
  position: absolute;
}
</style>
